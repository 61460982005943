import { baseApiSlice } from './base-api-slice';

import { Checklist } from '@/interfaces/checklist/checklist';
import { ChecklistAnswers } from '@/interfaces/checklist/checklist-answers';
import { Recommendation } from '@/interfaces/checklist/checklist-recommendations-card';

const checklistApiSlice = baseApiSlice.enhanceEndpoints({ addTagTypes: ['ChecklistResults'] }).injectEndpoints({
  endpoints: (builder) => ({
    getChecklistAnswers: builder.query<ChecklistAnswers[], string[]>({
      query: (teamIds: string[]) =>
        `checklist/get-checklists-result?${new URLSearchParams(teamIds.map((id) => ['teamId', id])).toString()}`,
      transformResponse: (response: { data: ChecklistAnswers[] }) => response.data,
      providesTags: ['ChecklistResults'],
    }),
    getRecommendations: builder.query<Recommendation[], void>({
      query: () => 'checklist/get-recommendations',
      transformResponse: (response: { data: { recommendations: Recommendation[] } }) => response.data.recommendations,
    }),
    getChecklistQuestions: builder.query<Checklist, void>({
      query: () => 'checklist/get-checklists',
      transformResponse: (response: { data: Checklist }) => response.data,
    }),
    deleteUnfinishedChecklist: builder.mutation<void, { teamId: string }>({
      query(body) {
        return {
          url: 'checklist/delete-unfinished',
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['ChecklistResults'],
    }),
    saveChecklist: builder.mutation<ChecklistAnswers, ChecklistAnswers>({
      query(body) {
        return {
          url: 'checklist/save-checklist-result',
          method: 'POST',
          body,
        };
      },
      transformResponse: (response: { data: ChecklistAnswers }) => response.data,
      async onQueryStarted(_updateArgs, { dispatch, queryFulfilled, getState }) {
        const { data: updatedChecklist } = await queryFulfilled;
        const {
          user: {
            user: { teams: userTeams },
          },
        } = getState() as any;
        dispatch(
          checklistApiSlice.util.updateQueryData('getChecklistAnswers', userTeams, (userChecklists) => {
            const index = userChecklists.findIndex((list) => list._id === updatedChecklist._id);
            if (index > -1) {
              const currentChecklists = userChecklists[index];
              Object.assign(userChecklists[index], {
                ...currentChecklists,
                ...updatedChecklist,
              });
            } else {
              userChecklists.push(updatedChecklist);
            }
          }),
        );
      },
    }),
  }),
});

export const {
  useGetChecklistAnswersQuery,
  useGetRecommendationsQuery,
  useGetChecklistQuestionsQuery,
  useDeleteUnfinishedChecklistMutation,
  useSaveChecklistMutation,
} = checklistApiSlice;
