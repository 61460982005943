import { ChecklistTeam } from '../../interfaces/checklist/checklist-team';
import { baseApiSlice } from './base-api-slice';

const teamApiSlice = baseApiSlice
  .enhanceEndpoints({
    addTagTypes: ['UserTeams'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserTeams: builder.query<ChecklistTeam[], void>({
        query: () => 'team/get-teams-by-user',
        transformResponse: (response: { data: ChecklistTeam[] }) => response.data,
        providesTags: ['UserTeams'],
      }),
      createTeam: builder.mutation<
        ChecklistTeam,
        Pick<ChecklistTeam, 'teamName' | 'clientId' | 'teamMembers' | 'createdByUser'>
      >({
        query(body) {
          return {
            url: 'team/create-team',
            method: 'POST',
            body,
          };
        },
        transformResponse: (response: { data: ChecklistTeam }) => response.data,
        async onQueryStarted(_createArgs, { dispatch, queryFulfilled }) {
          const { data: newTeam } = await queryFulfilled;
          dispatch(
            teamApiSlice.util.updateQueryData('getUserTeams', undefined, (userTeams) => {
              userTeams.push(newTeam);
            }),
          );
        },
      }),
      updateTeam: builder.mutation<ChecklistTeam, Pick<ChecklistTeam, '_id' | 'teamName' | 'teamMembers'>>({
        query(body) {
          const { _id: id, teamName, teamMembers } = body;
          return {
            url: 'team/update-team',
            method: 'POST',
            body: {
              id,
              teamName,
              teamMembers,
            },
          };
        },
        transformResponse: (response: { data: ChecklistTeam }) => response.data,
        invalidatesTags: ['UserTeams'],
      }),
      deleteTeam: builder.mutation<void, string>({
        query(id) {
          return {
            url: 'team/delete-team',
            method: 'POST',
            body: {
              id,
            },
          };
        },
        invalidatesTags: ['UserTeams'],
      }),
      pinRec: builder.mutation<ChecklistTeam, Pick<ChecklistTeam, '_id' | 'pinnedRecommendations'>>({
        query(body) {
          const { _id: id, pinnedRecommendations } = body;
          return {
            url: 'team/update-pinned-recommendations',
            method: 'POST',
            body: {
              pinnedRecommendations,
              id,
            },
          };
        },
        transformResponse: (response: { data: ChecklistTeam }) => response.data,
        async onQueryStarted(updateArgs, { dispatch, queryFulfilled }) {
          const postResult = dispatch(
            teamApiSlice.util.updateQueryData('getUserTeams', undefined, (userTeams) => {
              const index = userTeams.findIndex((team) => team._id === updateArgs._id);
              if (index > -1) {
                const currentTeam = userTeams[index];
                Object.assign(userTeams[index], {
                  ...currentTeam,
                  ...updateArgs,
                });
              }
            }),
          );
          try {
            await queryFulfilled;
          } catch {
            postResult.undo();
          }
        },
      }),
    }),
  });

export const {
  useGetUserTeamsQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  usePinRecMutation,
} = teamApiSlice;
